// @flow
import React from "react"
import LazyLoad from "react-lazyload"
import { RichText } from "prismic-reactjs"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  item?: Object,
  descriptionClassNames?: string,
  itemClassNames?: string,
|}

const Advantage = ({
  item = { icon: { url: "", alt: "" }, lg_description: [] },
  descriptionClassNames = "",
  itemClassNames = "",
}: Props) => (
  <div className={"flex w-full mb-32 md:mb-56" + itemClassNames}>
    <div className="flex justify-center min-w-advantage-sm sm:min-w-advantage-lg">
      <LazyLoad once>
        <img
          src={item.icon.url}
          alt={item.icon.alt}
          className={"w-58 h-58 xs_sm:w-85 xs_sm:h-85 sm:w-85 sm:h-85"}
        />
      </LazyLoad>
    </div>
    <RichText
      render={item.lg_description}
      linkResolver={linkResolver}
      htmlSerializer={htmlSerializerUpdateStyle(
        "",
        descriptionClassNames ||
          "text-left font-base-medium text-2sm font-medium leading-3normal text-white md:text-lg max-w-220"
      )}
    />
  </div>
)

export default Advantage
