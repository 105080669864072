// @flow
import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import LazyLoad from "react-lazyload"

import { formatRichTextLink } from "@lesmills/gatsby-theme-common"

type Props = {|
  data: Object,
  className?: string,
|}

const ChallengeYourself = ({ data, className = "" }: Props) => {
  const {
    challenge_yourself_title = {},
    challenge_yourself_subtitle = {},
    challenge_yourself_features = {},
    challenge_yourself_image = {},
    sign_up_button = {},
  } = data
  return (
    <section
      className={`bg-gray-130 flex flex-col md:flex-row items-center sm:pb-0${className}`}
    >
      <div className="lg:max-w-896 2xl:max-w-1070 3xl:max-w-1280 mx-auto flex flex-wrap lg:py-96 items-center">
        <div className="lg:w-1/2 w-full h-297 sm:h-605 lg:h-353 2xl:h-421 3xl:h-504">
          <LazyLoad once height="h-718">
            <img
              src={challenge_yourself_image.url}
              alt={challenge_yourself_image.alt}
              className="object-cover w-full h-full"
            />
          </LazyLoad>
        </div>
        <div className="lg:w-1/2 w-full sm:px-0 px-20 lg:pt-0 lg:pb-0 pt-32 pb-64">
          <div className="lg:ml-112 2xl:ml-129 3xl:ml-167 lg:max-w-441 sm:max-w-436 max-w-286 mx-auto">
            <h4 className="text-gray-800 font-primary text-6xl leading-30 sm:leading-28 uppercase mb-16 mt-50 md:mt-0 text-center lg:text-left">
              {challenge_yourself_title.text}
            </h4>
            <p className="mb-32 font-avenir-roman text-base leading-22 text-gray-800 text-center lg:text-left">
              {challenge_yourself_subtitle.text}
            </p>
            {challenge_yourself_features.length > 0 && (
              <ul className="list-inside list-disc ml-20 xs_sm:ml-x-30 md:ml-77 lg:ml-0">
                {challenge_yourself_features.map((item, index) => {
                  return (
                    <li
                      className="mb-16 font-avenir-roman text-3xs leading-22 text-gray-800"
                      key={"challenge-" + index}
                    >
                      {item.challenge_yourself_feature.text}
                    </li>
                  )
                })}
              </ul>
            )}
            {formatRichTextLink(
              sign_up_button.raw,
              "flex items-center justify-center uppercase bg-green-460 w-full sm:w-252 lg:w-157 text-gray-800 hover:text-white hover:bg-gray-970 cursor-pointer text-base leading-24 font-primary h-logo-lmod-base lg:mx-0 mx-auto sm:mb-20 mb-10 text-center no-underline mt-25"
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(ChallengeYourself, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
