// @flow
import React, { useState, useEffect, memo } from "react"
import LazyLoad from "react-lazyload"
import isEqual from "lodash/isEqual"

import { Icon, formatRichTextLink } from "@lesmills/gatsby-theme-common"

import Navigations from "./Navigations"

type Props = {|
  data: Object,
  isNewSalesLandingPage?: Boolean,
  innerRef?: () => void,
|}

const Header = ({
  data,
  isNewSalesLandingPage = false,
  innerRef = () => {},
}: Props) => {
  const [showSideBar, toggleSideBar] = useState(false)
  const [isScrollToTop, setIsScrollToTop] = useState(true)

  const {
    body9 = [],
    header_logo = {},
    start_free_trial_button_label = {
      raw: [],
    },
    header_logo_url = {},
  } = data || {}
  const [header_navigations = {}] = body9
  const navs = header_navigations.items || []
  useEffect(() => {
    if (isNewSalesLandingPage) {
      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsScrollToTop(true)
    } else if (isScrollToTop) {
      setIsScrollToTop(false)
    }
  }

  return (
    <header
      className={`${`fixed`} z-100 flex top-0 left-0 w-full items-center md:justify-between min-h-64 md:px-23 ${
        isScrollToTop && !showSideBar ? "bg-transparent" : "bg-gray-800"
      }`}
      data-cy="header"
      ref={innerRef}
    >
      <div className="flex flex-col w-full relative pt-3 md:flex-row md:pt-0 md:justify-between">
        <div className="flex pb-3 md:pb-0 items-center px-20">
          <Icon
            icon={showSideBar ? "cross-white" : "hamburger-white"}
            classNames={`${
              showSideBar ? "h-15" : "h-36 -ml-10"
            } bg-contain md:hidden w-36`}
            handleOnClick={() => toggleSideBar(!showSideBar)}
          />
          <LazyLoad once>
            <a href={header_logo_url.url}>
              <img
                src={header_logo.url}
                className="w-90 h-auto md:w-158 md:ml-0 absolute top-0 bottom-0 left-0 right-0 m-auto"
                alt={header_logo.alt}
                data-cy="lesmills-logo"
              />
            </a>
          </LazyLoad>
        </div>
        <Navigations
          navs={navs}
          classNames={{
            wrapper: `md:justify-end md:flex md:items-center ${
              showSideBar
                ? "flex-col absolute top-37 bg-gray-800 w-100vw z-40 md:mt-0 px-20"
                : "hidden"
            }`,
            item:
              "py-20 text-white font-primary text-base md:text-2lg md:leading-normal leading-18 uppercase hover:text-green-460 select-none py-20 md:ml-20 md:py-0",
          }}
          lastChild={formatRichTextLink(
            start_free_trial_button_label.raw,
            `uppercase cursor-pointer text-base md:text-2lg w-full font-primary py-13 px-20 block text-center ${
              isScrollToTop && !showSideBar
                ? "bg-opacity-0 border border-white text-white py-8 hover:text-green-460 hover:border-green-460"
                : "bg-green-460 text-black border-none"
            }`
          )}
          renderField="header_navigation_item"
        />
      </div>
    </header>
  )
}

export default memo(Header, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
