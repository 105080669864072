import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import LazyLoad from "react-lazyload"
import { RichText } from "prismic-reactjs"
import { htmlSerializerUpdateStyle } from "@lesmills/gatsby-theme-common"
type Props = {|
  data: Object,
  innerRef?: () => void,
|}

const ExtraInfo = ({ data, innerRef = () => {} }: Props) => {
  let { extra_info_image = {}, extra_info_content = {} } = data || {}
  const { url = "", alt = "" } = extra_info_image

  return (
    <section className="bg-gray-960" ref={innerRef}>
      <div className="flex justify-center items-center max-w-1200 mx-auto py-10 lg:py-13 py-16 uppercase text-white text-xs sm:text-3xs leading-20 font-avenir-medium max-w-70/100 sm:max-w-full tracking-1.28 text-center">
        <LazyLoad once>
          <img src={url} alt={alt} className="w-30 h-26 sm:mr-15" />
        </LazyLoad>
        <RichText
          render={extra_info_content.raw}
          htmlSerializer={htmlSerializerUpdateStyle(
            "",
            "w-184 sm:w-max-content"
          )}
        />
      </div>
    </section>
  )
}

export default memo(ExtraInfo, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
