import React, { memo } from "react"
import isEqual from "lodash/isEqual"

import LevelsAndGoals from "../LevelsAndGoals"

type Props = {|
  data: Object,
  title: Object,
|}

const WorkoutsInformation = ({ data, title }: Props) => {
  const [workouts = {}] = data || []
  const items = workouts.items || []

  const formatedData = {
    items: items.map(item => ({
      icon: item.workout_icon || {},
      lg_description: item.workout_description
        ? item.workout_description.raw
        : [],
    })),
    primary: {
      lg_title: title,
    },
  }

  return (
    <LevelsAndGoals
      data={formatedData}
      sectionClassNames={{
        title:
          "mb-40 text-gray-800 font-primary text-6xl sm:leading-28 leading-30 uppercase mx-auto max-w-70/100 sm:max-w-full md:mx-0",
      }}
      descriptionClassNames={
        "leading-22 font-base-heavy text-base text-left ml-20 sm:ml-0 xs_sm:text-center sm:text-center text-gray-800 xs_sm:mt-30 sm:mt-30 max-w-full xs_sm:max-w-220 sm:max-w-220"
      }
      wrapperClassNames="flex flex-wrap px-20 lg:px-0"
      advantageClassNames="flex sm:w-1/2 w-full mb-20 justify-between"
      itemClassNames=" md:max-w-49/100 lg:max-w-30/100 flex-row xs_sm:flex-col sm:flex-col items-center xs_sm:max-w-49/100 xs_sm:mb-56 ml-5 xs_sm:ml-0 sm:ml-0 sm:max-w-49/100"
    />
  )
}

export default memo(WorkoutsInformation, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
