// @flow
import React, { memo } from "react"

import Advantage from "./Advantage"
import DefaultSection from "../DefaultSection"

type Props = {|
  data: Object,
  sectionClassNames?: object,
  descriptionClassNames?: string,
  itemClassNames?: string,
|}

const LevelsAndGoals = ({
  data = {
    items: [],
    primary: {},
  },
  sectionClassNames = {},
  descriptionClassNames,
  itemClassNames,
}: Props) => (
  <DefaultSection
    title={data.primary.lg_title && data.primary.lg_title.text}
    testId="levels-and-goals-section"
    sectionClassNames={sectionClassNames}
    classNames={"pt-88 md:pt-96"}
    id="about"
    isPrimary={true}
  >
    <div className="flex flex-wrap px-20 lg:px-0 md:max-w-80/100 xl:max-w-60/100 3xl:max-w-1440 mx-auto md:justify-between justify-center lg:mb-40">
      {data.items.map((item, key) => (
        <Advantage
          item={item}
          key={"levels-goals-" + key}
          descriptionClassNames={descriptionClassNames}
          itemClassNames={itemClassNames}
        />
      ))}
    </div>
  </DefaultSection>
)

export default memo<Props>(LevelsAndGoals)
