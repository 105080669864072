import React, { memo } from "react"
import isEqual from "lodash/isEqual"

import Hero from "../RefinedSaleLandingPage/Hero"
import FrequentlyAskedQuestions from "../FrequentlyAskedQuestions"
import TryTheApp from "../TryTheApp"
import WorkoutsInformation from "../RefinedSaleLandingPage/WorkoutsInformation"
import Stream from "../RefinedSaleLandingPage/Stream"
import Testimonials from "../RefinedSaleLandingPage/Testimonials"
import JoinCommunity from "../JoinCommunity"
import WorkoutVideos from "../RefinedSaleLandingPage/WorkoutVideos"
import ExtraInfo from "../RefinedSaleLandingPage/ExtraInfo"
import ChallengeYourself from "../RefinedSaleLandingPage/ChallengeYourself"
import { isBrowser } from "@lesmills/gatsby-theme-common"

type Props = {
  data: Object,
  isNewSalesLandingPage: Boolean,
  heroData: Object,
  isShowHero?: boolean,
  isShowExtraInfo?: boolean,
  isShowChallengeYourself?: boolean,
  isShowTryTheApp?: boolean,
  extraInfoData?: boolean,
  challengeYourselfData: object,
  extraInfoSectionRef: object,
  handleClickDiscover: () => void,
}

const MarketingSalesLanding = ({
  data = {},
  isNewSalesLandingPage = false,
  heroData,
  isShowHero,
  isShowExtraInfo,
  isShowChallengeYourself,
  isShowTryTheApp,
  extraInfoData,
  challengeYourselfData,
  extraInfoSectionRef,
  handleClickDiscover,
}: Props) => {
  const {
    stream_subtitle = {},
    join_community_subtitle = {},
    join_community_title = {},
    faq_title = {},
    stream_title = {},
    stream_photo = {},
    embedded_video_title = {},
    embedded_video_subtitle = {},
    workout_title = {},
  } = data

  const ordering = (data.body10 && data.body10[0] && data.body10[0].items) || []
  const workouts = data.body1 || []
  const embed_video = data.body2 || []
  const stream = data.body4 || []
  const testimonials = data.body5 || []
  const faqs = data.body6 || []
  const join_community = data.body7 || []

  const modules = {
    ...(isShowHero && {
      hero: (
        <Hero
          data={heroData}
          handleClickDiscover={handleClickDiscover}
          isNewSalesLandingPage={isNewSalesLandingPage}
        />
      ),
    }),
    ...(isShowExtraInfo && {
      extra_info: (
        <ExtraInfo innerRef={extraInfoSectionRef} data={extraInfoData} />
      ),
    }),
    ...(isShowChallengeYourself && {
      challenge_yourself: (
        <ChallengeYourself
          data={challengeYourselfData}
          className=" mt-88 md:mt-96"
        />
      ),
    }),
    ...(isShowTryTheApp && {
      try_the_app: (
        <TryTheApp
          data={data}
          classNames={{
            image: " lg:w-252",
            wrapper: "sm:my-96 my-58",
          }}
        />
      ),
    }),
    ...(embed_video.length > 0 && {
      programs: (
        <WorkoutVideos
          data={embed_video}
          title={embedded_video_title}
          subtitle={embedded_video_subtitle}
          className=" pt-88 md:pt-96"
        />
      ),
    }),
    ...(workouts.length > 0 && {
      workouts: <WorkoutsInformation data={workouts} title={workout_title} />,
    }),
    ...(join_community.length > 0 && {
      community: (
        <JoinCommunity
          data={join_community}
          title={join_community_title}
          subtitle={join_community_subtitle}
          className="mx-auto max-w-1280 bg-white pt-35 md:pt-64"
        />
      ),
    }),
    ...(testimonials.length > 0 && {
      testimonials: (
        <Testimonials
          data={testimonials}
          itemClassName="py-48 lg:px-24 sm:px-20 px-24 mb-24 lg:mb-0 shadow lg:w-1/3 lg:max-w-288 2xl:max-w-346 w-full text-white bg-gray-800"
          wrapperClassName="flex flex-wrap mt-32 lg:justify-between justify-center mx-auto max-w-335 xs_sm:max-w-504 md:max-w-439 lg:max-w-928 2xl:max-w-1107"
        />
      ),
    }),
    ...(stream.length > 0 && {
      stream: (
        <Stream
          data={stream}
          title={stream_title}
          subtitle={stream_subtitle}
          photo={stream_photo}
          classNames={{
            deviceIcons: "lg:max-w-715 sm:mt-50 md:mt-60",
          }}
        />
      ),
    }),
    ...(faqs.length > 0 && {
      faqs: (
        <section id="faqs" className="mt-70">
          <FrequentlyAskedQuestions
            data={faqs}
            title={faq_title}
            opened={
              isBrowser && window && window.innerWidth > 600 ? true : false
            }
            classNames={{}}
          />
        </section>
      ),
    }),
  }

  return (
    <>
      {ordering.map(({ module_id, display }) => {
        return display ? modules[module_id] : null
      })}
    </>
  )
}

export default memo(MarketingSalesLanding, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
