import React, { useState, useEffect, useRef, memo } from "react"
import isEqual from "lodash/isEqual"

import {
  publicAPI,
  getListProducts,
  handleErrorWithPrismic,
  isBrowser,
  getShortestTrialProduct,
  Spinner,
  isEmpty,
  isExistDataSection,
  setSessionStorage,
  getSessionStorage,
  CURRENT_COUNTRY,
  redirectTo,
  ROUTES,
} from "@lesmills/gatsby-theme-common"
import { AXII_CONFIGS } from "../../../../../src/configs/axii-config"

import Header from "./Header"
import Hero from "./Hero"
import FrequentlyAskedQuestions from "../FrequentlyAskedQuestions"
import TryTheApp from "../TryTheApp"
import WorkoutsInformation from "./WorkoutsInformation"
import Stream from "./Stream"
import Testimonials from "./Testimonials"
import JoinCommunity from "../JoinCommunity"
import WorkoutVideos from "./WorkoutVideos"
import ExtraInfo from "./ExtraInfo"
import ChallengeYourself from "./ChallengeYourself"
import MarketingSalesLanding from "../MarketingSalesLanding"
import loadable from "@loadable/component"

const StickyToast = loadable(() => import("./StickyToast"), {
  fallback: <Spinner />,
})

const Footer = loadable(() => import("../Footer"), {
  fallback: <Spinner />,
})

type Props = {
  data: Object,
  layoutData: LayoutType,
  isNewSalesLandingPage?: Boolean,
  lang?: string,
}

const RefinedSaleLanding = ({
  data = {},
  layoutData,
  isNewSalesLandingPage = false,
  lang,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState(null)

  const {
    stream_subtitle = {},
    join_community_subtitle = {},
    join_community_title = {},
    faq_title = {},
    stream_title = {},
    stream_photo = {},
    embedded_video_title = {},
    embedded_video_subtitle = {},
    workout_title = {},
    footer_copyright = {},
    try_the_app_subtitle = {},
    try_the_app_title = {},
    try_the_app_image = {},
    start_free_trial_button_label = {},
    hero_subtitle = {},
    see_prices_button_label = {},
    cancel_free_trial_note = {},
    lmod_lpbc_err = {},
    extra_info_image = {},
    extra_info_content = {},
    unlimited_access_text = {},
    hero_title = {},
    discover_button_label = {},
    join_us_content = {},
    hero_video = {},
    hero_image = {},
    hero_logo = {},
    challenge_yourself_title = {},
    challenge_yourself_subtitle = {},
    challenge_yourself_features = {},
    challenge_yourself_image = {},
    sign_up_button = {},
  } = data

  const workouts = data.body1 || []
  const embed_video = data.body2 || []
  const stream = data.body4 || []
  const testimonials = data.body5 || []
  const faqs = data.body6 || []
  const join_community = data.body7 || []
  const footer = data.body8 || []

  const [footer_navigations, footer_socials] = footer
  const ordering = (data.body10 && data.body10[0] && data.body10[0].items) || []

  // Format footer data
  let navigations = { items: [] }
  let socials = { items: [] }

  footer_navigations &&
    footer_navigations.items.map(item => {
      navigations.items.push({
        navigation: item.footer_navigation_label,
      })
    })

  footer_socials &&
    footer_socials.items.map(item => {
      socials.items.push({
        icon: item.icon,
        link: item.footer_social_url,
      })
    })

  const footerData = {
    navigation: navigations,
    social: socials,
  }

  const tryTheAppData = {
    try_the_app_title,
    try_the_app_subtitle,
    try_the_app_image,
  }

  const stickyData = {
    unlimited_access_text,
    see_prices_button_label,
    start_free_trial_button_label,
  }

  const heroData = {
    hero_title,
    hero_subtitle,
    start_free_trial_button_label,
    see_prices_button_label,
    cancel_free_trial_note,
    discover_button_label,
    join_us_content,
    hero_video,
    hero_image,
    hero_logo,
  }

  const challengeYourselfData = {
    challenge_yourself_title,
    challenge_yourself_subtitle,
    challenge_yourself_features,
    challenge_yourself_image,
    sign_up_button,
  }

  const extraInfoData = {
    extra_info_image,
    extra_info_content,
  }

  const isShowHero = isExistDataSection(heroData)
  const isShowExtraInfo = isExistDataSection(extraInfoData)
  const isShowChallengeYourself = isExistDataSection(challengeYourselfData)
  const isShowTryTheApp = isExistDataSection(tryTheAppData)

  const getTrialUnitText = (trial_interval_unit, trial_interval) => {
    const unit =
      trial_interval_unit +
      `${trial_interval && trial_interval > 1 ? "_plural" : "_singular"}`

    return layoutData[unit].text
  }

  const callBackAfterGetCountryCode = countryCode => {
    // With enabled countries can go through Axii pages
    const isEnabledCountries =
      AXII_CONFIGS.enabledCountries.indexOf(countryCode.toLowerCase()) > -1

    if (!getSessionStorage(CURRENT_COUNTRY)) {
      setSessionStorage(CURRENT_COUNTRY, countryCode)
    }

    if (!isEnabledCountries) {
      redirectTo(ROUTES().LMOD_CUSTOMER_WATCH)
      return
    }
  }

  const handleProductList = res => {
    const countryCode = res.data.data
      ? res.data.data.getValidProducts.countryCode.toLowerCase()
      : ""
    const products = res.data.data
      ? res.data.data.getValidProducts.products
      : []

    if (countryCode) {
      callBackAfterGetCountryCode(countryCode)
    }

    const shortestProduct = getShortestTrialProduct(products)
    setProduct(shortestProduct)

    const {
      trial_interval,
      trial_interval_unit,
    } = shortestProduct.product_price_point

    const trialIntervalUnit = layoutData[
      trial_interval_unit + "_singular"
    ].text.toLowerCase()

    hero_subtitle.text = hero_subtitle.text
      .replace("@trial_interval", trial_interval)
      .replace("@trial_interval_unit", trialIntervalUnit)

    hero_subtitle.raw[0] = {
      ...hero_subtitle.raw[0],
      text: hero_subtitle.text,
    }
    setLoading(false)
  }

  const handleError = err => {
    handleErrorWithPrismic(err, lmod_lpbc_err.text, () => {}, data)
  }

  const getProductList = () => {
    publicAPI(getListProducts, handleProductList, handleError)
  }

  useEffect(() => {
    if (isNewSalesLandingPage) {
      getProductList()
    }
  }, [])

  const headerSectionRef = useRef(null)
  const extraInfoSectionRef = useRef(null)
  const handleClickDiscover = () => {
    isBrowser &&
      window.scrollTo({
        left: 0,
        top:
          extraInfoSectionRef.current.offsetTop -
          headerSectionRef.current.clientHeight,
        behavior: "smooth",
      })
  }

  return (
    <div className="marketing-page">
      {isNewSalesLandingPage && product && !isEmpty(product) && (
        <StickyToast
          product={product}
          prismicData={stickyData}
          getTrialUnitText={getTrialUnitText}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Header
            data={data}
            isNewSalesLandingPage={isNewSalesLandingPage}
            innerRef={headerSectionRef}
          />
          {ordering.length > 0 ? (
            <MarketingSalesLanding
              data={data}
              isNewSalesLandingPage={isNewSalesLandingPage}
              heroData={heroData}
              lang={lang}
              isShowHero={isShowHero}
              isShowExtraInfo={isShowExtraInfo}
              isShowChallengeYourself={isShowChallengeYourself}
              isShowTryTheApp={isShowTryTheApp}
              extraInfoData={extraInfoData}
              challengeYourselfData={challengeYourselfData}
              footerData={footerData}
              extraInfoSectionRef={extraInfoSectionRef}
              headerSectionRef={headerSectionRef}
              handleClickDiscover={handleClickDiscover}
              product={product}
            />
          ) : (
            <>
              {isShowHero && (
                <Hero
                  data={heroData}
                  handleClickDiscover={handleClickDiscover}
                  isNewSalesLandingPage={isNewSalesLandingPage}
                />
              )}
              {isShowExtraInfo && (
                <ExtraInfo
                  innerRef={extraInfoSectionRef}
                  data={extraInfoData}
                />
              )}
              {embed_video.length > 0 && (
                <WorkoutVideos
                  data={embed_video}
                  title={embedded_video_title}
                  subtitle={embedded_video_subtitle}
                />
              )}
              {isShowChallengeYourself && (
                <ChallengeYourself data={challengeYourselfData} />
              )}
              {workouts.length > 0 && (
                <WorkoutsInformation data={workouts} title={workout_title} />
              )}
              {join_community.length > 0 && (
                <JoinCommunity
                  data={join_community}
                  title={join_community_title}
                  subtitle={join_community_subtitle}
                  className="bg-white mx-auto max-w-1280"
                  itemClassName="py-48 lg:px-24 sm:px-20 px-24 mb-24 lg:mb-0 shadow lg:w-1/3 lg:max-w-288 2xl:max-w-346 w-full text-white bg-gray-800"
                />
              )}
              {testimonials.length > 0 && (
                <Testimonials
                  data={testimonials}
                  wrapperClassName="flex flex-wrap mt-32 lg:justify-between justify-center mx-auto max-w-335 xs_sm:max-w-504 md:max-w-439 lg:max-w-928 2xl:max-w-1107"
                />
              )}
              {stream.length > 0 && (
                <Stream
                  data={stream}
                  title={stream_title}
                  subtitle={stream_subtitle}
                  photo={stream_photo}
                />
              )}
              {faqs.length > 0 && (
                <section id="faqs">
                  <FrequentlyAskedQuestions
                    data={faqs}
                    title={faq_title}
                    opened={
                      isBrowser && window && window.innerWidth > 600
                        ? true
                        : false
                    }
                    classNames={{}}
                  />
                </section>
              )}
              {isShowTryTheApp && (
                <TryTheApp
                  data={data}
                  classNames={{
                    image: " lg:w-252",
                    wrapper: "sm:my-96 my-58",
                  }}
                />
              )}
            </>
          )}

          <Footer
            links={footerData}
            copyright={footer_copyright.text}
            isNewSalesLandingPage={isNewSalesLandingPage}
            product={product}
          />
        </>
      )}
    </div>
  )
}

export default memo(RefinedSaleLanding, (prevProps, nextProps) => {
  return isEqual(prevProps.data, nextProps.data)
})
