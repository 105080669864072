import * as React from "react"
import { RichText } from "prismic-reactjs"

import { linkResolver } from "@lesmills/gatsby-theme-common"

type Props = {|
  navs: Array<Object>,
  classNames?: Object,
  renderField?: string,
  lastChild?: React.Node,
|}

const Navigations = ({
  navs,
  classNames = {},
  renderField = "label",
  lastChild,
}: Props) => (
  <ul className={classNames.wrapper}>
    {navs.map((link, index) => {
      return (
        <li
          className={classNames.item}
          key={`${link[renderField].text}-${index}`}
        >
          <RichText
            render={link[renderField].raw}
            linkResolver={linkResolver}
          />
        </li>
      )
    })}
    {lastChild && <li className={classNames.item}>{lastChild}</li>}
  </ul>
)

export default Navigations
